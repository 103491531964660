/*!
 * Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
@import 'functions';
@import 'variables';

:root, :host {
  --#{$fa-css-prefix}-font-light: normal 300 1em/1 "#{ $fa-style-family }";
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-light-300.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-light-300.ttf') format('truetype');
}

.fal,
.#{$fa-css-prefix}-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
}
